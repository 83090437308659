import { LogoutOutlined } from '@ant-design/icons';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid2 as Grid, Paper, Popper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSignOut } from 'api/auth';
import Avatar from 'components/@extended/Avatar';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { AuthContext } from 'contexts/auth';
import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Profile = () => {
  const theme = useTheme();

  const { mutate } = useSignOut();

  const { user } = useContext(AuthContext);

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={(theme) => ({
          p: 0.25,
          borderRadius: 1,
          bgcolor: open ? 'grey.300' : 'transparent',
          ...theme.applyStyles('dark', {
            bgcolor: 'grey.200'
          }),
          '&:hover': {
            bgcolor: 'secondary.lighter',
            ...theme.applyStyles('dark', {
              bgcolor: 'secondary.light'
            })
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        })}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={user?.avatar} size="xs" />
          <Typography variant="subtitle1">{user?.name}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent>
                      <Stack justifyContent="space-between" spacing={2} alignItems="center" direction="row">
                        <Grid>
                          <ButtonBase sx={{ p: 0.25 }} component={Link} to={`/profile`}>
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              <Avatar alt="profile user" src={user?.avatar} sx={{ width: 32, height: 32 }} />
                              <Stack>
                                <Typography variant="h6">View Profile</Typography>
                              </Stack>
                            </Stack>
                          </ButtonBase>
                        </Grid>
                        <Grid>
                          <ButtonBase
                            sx={{ p: 0.25 }}
                            onClick={() =>
                              mutate(
                                {},
                                {
                                  onSuccess: () => {
                                    localStorage.clear();
                                    window.location.reload();
                                  }
                                }
                              )
                            }
                          >
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              <LogoutOutlined />
                              <Stack>
                                <Typography variant="h6">Logout</Typography>
                              </Stack>
                            </Stack>
                          </ButtonBase>
                        </Grid>
                      </Stack>
                    </CardContent>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
