import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const expenses: NavItemType = {
  id: 'accounting',
  type: 'group',
  title: <FormattedMessage id="accounting" />,
  children: [
    {
      id: 'journals',
      title: <FormattedMessage id="journal-entries" />,
      type: 'item',
      url: '/app/accounting/journal-entries'
    },
    {
      id: 'gl',
      title: <FormattedMessage id="general-ledger" />,
      type: 'item',
      url: '/app/accounting/gl'
    }
  ]
};

export default expenses;
