import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - MuiDatePicker ||============================== //

export default function DatePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          backgroundColor: 'red'
        }
      }
    }
  };
}
