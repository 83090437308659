import axios from 'axios';

export const base_url = process.env.REACT_APP_BASE_URL || '';
axios.defaults.baseURL = base_url;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const axiosServices = axios.create();

axiosServices.interceptors.request.use(
  (config) => {
    const serviceToken = localStorage.getItem('serviceToken');

    if (serviceToken) {
      config.headers['Authorization'] = `Token ${serviceToken}`;

      if (config.method === 'get') {
        const cacheKey = localStorage.getItem('cacheTracker');
        const params = config.params || {};
        if (cacheKey) {
          params['k'] = cacheKey;
        }
        config.params = params;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => {
    const cacheKey = response.headers['cache-version'];

    if (cacheKey) {
      localStorage.setItem('cacheTracker', cacheKey);
    } else {
      localStorage.removeItem('cacheTracker');
    }
    return response;
  },
  (err) => {
    if (err.name === 'AxiosError' && err.code === 'ERR_NETWORK') {
      return Promise.reject({
        status: 500,
        errors: err.message
      });
    }

    if (err.response) {
      const { status, data } = err.response;
      if (status === 404) {
        return Promise.reject({
          status: 404,
          errors: 'Requested resource not found'
        });
      }
      if (status >= 500 && status < 600) {
        return Promise.reject({
          status: status,
          errors: 'Server error'
        });
      }
      const { status_code, ...errors } = data;
      const { detail } = data;

      if (typeof detail == 'string') {
        const dLower = detail?.toLowerCase();
        if (dLower?.includes('credentials were not provided') || dLower?.includes('invalid token')) {
          localStorage.clear();
        }
      }

      return Promise.reject({
        code: status_code,
        status,
        errors
      });
    }
    return Promise.reject(err);
  }
);

export default axiosServices;
