import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000
    }
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      const { status } = error;
      if (Number(status) >= 500) {
        Sentry.captureException(error);
        dispatch(
          openSnackbar({
            open: true,
            message: 'A server error occurred. Please try again later.',
            alert: {
              color: 'error'
            }
          })
        );
      }
    }
  })
});
