import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'store';

import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { drawerWidth } from 'config';
import { openComponentDrawer } from 'store/reducers/menu';
import Drawer from './Drawer';

// components content
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(({ theme }) => ({
  minHeight: `calc(100vh - 188px)`,
  width: `calc(100% - ${drawerWidth}px)`,
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0
  },
  variants: [
    {
      props: ({ open }: { open: boolean }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    }
  ]
}));

// ==============================|| COMPONENTS LAYOUT ||============================== //

interface Props {
  handleDrawerOpen: () => void;
  componentDrawerOpen: boolean;
}

const ComponentsLayout = ({ handleDrawerOpen, componentDrawerOpen }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(openComponentDrawer({ componentDrawerOpen: !matchDownMd }));
  }, [matchDownMd]);

  return (
    <Box sx={{ display: 'flex', pt: componentDrawerOpen ? { xs: 0, md: 3, xl: 5.5 } : 0 }}>
      <Drawer handleDrawerOpen={handleDrawerOpen} open={componentDrawerOpen} />
      <Main open={componentDrawerOpen}>
        <Outlet />
      </Main>
    </Box>
  );
};
export default ComponentsLayout;
