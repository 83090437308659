// ==============================|| OVERRIDES - DIALOG TITLE ||============================== //

export default function DialogTitle() {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '1.5rem',
          lineHeight: 1.33,
          m: '0px auto',
          alignSelf: 'center'
        }
      }
    }
  };
}
