import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ColorProps } from 'types/extended';
import getColors from 'utils/getColors';

interface Props {
  color?: ColorProps;
  size?: number;
  variant?: string;
}

const Dot = ({ color, size, variant }: Props) => {
  const theme = useTheme();
  const colors = getColors(theme, color || 'primary');
  const { main } = colors;

  return (
    <Box
      component="span"
      sx={[
        {
          width: size || 8,
          height: size || 8,
          borderRadius: '50%'
        },
        variant === 'outlined'
          ? {
              bgcolor: '',
              border: `1px solid ${main}`
            }
          : {
              bgcolor: main
            }
      ]}
    />
  );
};

export default Dot;
