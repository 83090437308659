import { ReactNode, useMemo } from 'react';

import { AppBar, AppBarProps, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import IconButton from 'components/@extended/IconButton';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from 'components/logo';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  fullWidth: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, fullWidth, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // header content
  const headerContent = useMemo(() => <HeaderContent fullWidth={fullWidth} />, [fullWidth]);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar sx={{ m: 0, px: 2 }}>
      {fullWidth && (
        <Logo
          height={35}
          sx={[
            {
              height: 35,
              width: 'auto'
            },
            open
              ? {
                  width: 'auto'
                }
              : {
                  width: 35
                }
          ]}
        />
      )}

      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        sx={[
          {
            color: 'text.primary',
            ml: { xs: 0, lg: open ? -2 : 6 }
          },
          open
            ? {
                bgcolor: iconBackColorOpen
              }
            : {
                bgcolor: iconBackColor
              }
        ]}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  };
  const AppBarComponent = matchDownMD ? AppBar : AppBarStyled;

  return (
    <AppBarComponent open={open && !fullWidth} {...appBar}>
      {mainHeader}
    </AppBarComponent>
  );
};

export default Header;
