import { lazy } from 'react';

import Loadable from 'components/Loadable';
import CommonLayout from 'layout/CommonLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';

// render - login
const AuthCheckCode = Loadable(lazy(() => import('pages/auth/check-code')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/auth',
          element: <AuthLogin />
        },
        {
          path: '/auth/login',
          element: <AuthLogin />
        },
        {
          path: '/auth/forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: '/auth/check-code',
          element: <AuthCheckCode />
        },
        {
          path: '/auth/reset-password',
          element: <AuthResetPassword />
        },
        {
          path: '/auth/*',
          element: <Error404 />
        }
      ]
    }
  ]
};

export default LoginRoutes;
