// ==============================|| OVERRIDES - ListSubheader ||============================== //

import { Theme } from '@mui/material/styles';

export default function ListSubheader(theme: Theme) {
  return {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWight: '700',
          color: theme.palette.text.primary
        }
      }
    }
  };
}
