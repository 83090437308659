import { Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';

import useConfig from 'hooks/useConfig';

const NetworkStatusIndicator = () => {
  const { isOnline } = useConfig();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Dialog open={!isOnline}>
        <DialogTitle sx={{ m: '0px auto', alignSelf: 'center' }}>You are offline.</DialogTitle>
        <DialogContent>
          <Typography> An internet connection is required to continue</Typography>
        </DialogContent>
        <Divider />
      </Dialog>
    </Stack>
  );
};

export default NetworkStatusIndicator;
