import { get } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { ThemeMode } from 'types/config';
import { DATE_FORMAT } from './constants';

export const useInterval = (callback: any, delay: any) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback !== undefined) {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export function setWithExpiry(key: string, value: string, ttl: number) {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function splitOnCapital(str: string) {
  if (!str) {
    return '';
  }
  const splitted = str
    .trim()
    .split(/(?=[A-Z])/)
    .map((element) => element.trim());
  return splitted.join(' ');
}

export function titleWithRef(str: string, ref: string) {
  if (!ref) {
    return '-';
  }
  return `${splitOnCapital(str)} #${ref}`;
}

export function getThemeMode(): ThemeMode {
  const theme = localStorage.getItem('theme');
  if (theme) {
    if (['light', 'dark'].includes(theme)) {
      return theme as ThemeMode;
    } else if (theme === 'auto') {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
  }
  return 'light';
}

export async function createFile(url: string, name: string, extension: string) {
  if (url) {
    const response = await fetch(url);
    const data = await response.blob();

    return new File([data], `${name}.${extension}`, { type: data.type });
  }
}

export const getMaxYear = (max: number = 10) => {
  const date = new Date();

  date.setFullYear(date.getFullYear() + max);
  return date;
};

export const getMinYear = (min: number = 1) => {
  const date = new Date();

  date.setFullYear(date.getFullYear() - min);
  return date;
};

export const formatDate = (date: string, date_format = DATE_FORMAT) => {
  if (/^\d+$/.test(date)) {
    return date;
  }

  if (!date) {
    return '-';
  }

  return DateTime.fromISO(date, {
    zone: 'utc'
  })
    .setZone('Africa/Nairobi')
    .toFormat(date_format);
};

export const getDateValue = (value: any) => {
  if (value === null) {
    return value;
  }
  let date;
  if (!value || value === undefined) {
    date = new Date();
  } else if (typeof value === 'string') {
    date = new Date(value);
  } else if (DateTime.isDateTime(value)) {
    date = value.toJSDate();
  } else {
    date = value;
  }
  return DateTime.fromJSDate(date, { zone: 'Africa/Nairobi' });
};

export const formatSeparated = (params: { item: any; key: string[]; format?: string; suffix?: string; separator?: string }) => {
  const { item, key, format, suffix, separator } = params;
  const title = key
    ?.filter((each: string) => {
      return !!get(item, each);
    })
    ?.map((each) => {
      let val = get(item, each);

      try {
        val = formatDate(val, format || DATE_FORMAT);
      } catch {
        /* empty */
      }
      return val;
    })
    .join(separator || '-');
  if (suffix) {
    return `${title} ${suffix}`;
  }
  return title;
};
