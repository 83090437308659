import _ from 'lodash';
import { requestParams } from 'types/table';
import { list, retrieve } from './base';

export const getClients = (params?: requestParams) => {
  const url = '/clients';
  return list(url, params);
};

export const getClient = (id?: string) => {
  if (id === 'reset') return getClients();
  if (Number(id)) return getClientByCode(id);
  const url = `/clients/${id}`;

  return retrieve(url, !_.isEmpty(id));
};

export const getClientByCode = (code?: string) => {
  const url = `/clients/${code}/id`;
  return retrieve(url, !_.isEmpty(code));
};
