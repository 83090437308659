import { ChromeOutlined, CustomerServiceOutlined, QuestionOutlined, ShoppingOutlined, StopOutlined, TeamOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  CustomerServiceOutlined,
  ShoppingOutlined,
  StopOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const users: NavItemType = {
  id: 'users',
  type: 'group',
  title: <FormattedMessage id="users" />,
  children: [
    {
      id: 'customers',
      title: <FormattedMessage id="customers" />,
      type: 'item',
      url: '/app/customers',
      icon: icons.CustomerServiceOutlined
    },
    {
      id: 'suppliers',
      title: <FormattedMessage id="suppliers" />,
      type: 'item',
      url: '/app/suppliers',
      icon: icons.ShoppingOutlined
    },
    {
      id: 'staff',
      title: <FormattedMessage id="staff" />,
      type: 'item',
      url: '/app/staff',
      icon: icons.TeamOutlined
    }
  ]
};

export default users;
