import adminNavigation from 'menu-items/admin';
import mainNavigation from 'menu-items/main';
import setupNavigation from 'menu-items/setup';
import { NavItemType } from 'types/menu';

export const getMenuItems = (
  user: any,
  isActive: undefined | boolean,
  default_role: string,
  company_type: string,
  company_id: string
): { items: NavItemType[] } => {
  if (user) {
    if (user?.is_staff && company_type === 'system') {
      return adminNavigation;
    }
    if (isActive && (default_role || company_id)) {
      return mainNavigation;
    }
    return setupNavigation;
  }

  return { items: [] };
};
