import Logo from 'components/logo';
import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  return (
    <DrawerHeaderStyled open={open}>
      <Logo
        isIcon={!open}
        height={35}
        sx={[
          {
            height: 35,
            width: 'auto'
          },
          open
            ? {
                width: 'auto'
              }
            : {
                width: 35
              }
        ]}
      />
    </DrawerHeaderStyled>
  );
};
export default DrawerHeader;
