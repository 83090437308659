import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const sales: NavItemType = {
  id: 'sales',
  type: 'group',
  title: <FormattedMessage id="sales" />,
  children: [
    {
      id: 'cash sales',
      title: <FormattedMessage id="cash sales" />,
      type: 'item',
      url: '/app/sales/cash-sales'
    },
    {
      id: 'fee notes',
      title: <FormattedMessage id="fee notes" />,
      type: 'item',
      url: '/app/sales/fee-notes'
    },
    {
      id: 'sales-orders',
      title: <FormattedMessage id="sales-orders" />,
      type: 'item',
      url: '/app/sales/orders'
    },
    {
      id: 'quotations',
      title: <FormattedMessage id="sales-quotations" />,
      type: 'item',
      url: '/app/sales/quotations'
    },
    {
      id: 'invoices',
      title: <FormattedMessage id="invoices" />,
      type: 'item',
      url: '/app/sales/invoices'
    },
    {
      id: 'deliveries',
      title: <FormattedMessage id="sales-deliveries" />,
      type: 'item',
      url: '/app/sales/deliveries'
    },
    {
      id: 'credit-notes',
      title: <FormattedMessage id="sales-credit-notes" />,
      type: 'item',
      url: '/app/sales/credit-notes'
    }
  ]
};

export default sales;
