const ErrorFallback = ({ error }: { error?: string }) => {
  return (
    <div className="error-boundary-fallback-wrapper">
      <h1>Something went wrong</h1>
      <p>{error}</p>
    </div>
  );
};

export default ErrorFallback;
