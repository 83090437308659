export const OPEN = 'Open';
export const FIXED = 'Fixed';
export const TASK = 'Task Based';
export const CASUAL = 'Casual';
export const TEXT = 'text';
export const SELECT = 'select';
export const MULTI_SELECT = 'multi select';
export const ADDRESS = 'address';
export const EMAIL = 'email';
export const PHONE = 'phone number';
export const NUMBER = 'number';
export const DATE = 'date';
export const USER = 'user';
export const DATE_FORMAT = 'dd-MM-yyyy';
export const FILE_TYPES = new Map([
  ['aac', 'audio/aac'],
  ['avi', 'video/x-msvideo'],
  ['css', 'text/css'],
  ['csv', 'text/csv'],
  ['doc', 'application/msword'],
  ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  ['gif', 'image/gif'],
  ['html', 'text/html'],
  ['htm', 'text/html'],
  ['ico', 'image/vnd.microsoft.icon'],
  ['jpeg', 'image/jpeg'],
  ['jpg', 'image/jpeg'],
  ['js', 'text/javascript'],
  ['json', 'application/json'],
  ['mp3', 'audio/mpeg'],
  ['mp4', 'video/mp4'],
  ['mpeg', 'video/mpeg'],
  ['png', 'image/png'],
  ['pdf', 'application/pdf'],
  ['php', 'application/x-httpd-php'],
  ['ppt', 'application/vnd.ms-powerpoint'],
  ['pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  ['svg', 'image/svg+xml'],
  ['tiff', 'image/tiff'],
  ['tif', 'image/tiff'],
  ['txt', 'text/plain'],
  ['webm', 'video/webm'],
  ['xhtml', 'application/xhtml+xml'],
  ['xls', 'application/vnd.ms-excel'],
  ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ['xml', 'application/xml'],
  ['zip', 'application/zip'],
  ['3gp', 'audio/3gpp'],
  ['3gp', 'video/3gpp'],
  ['7z', 'application/x-7z-compressed']
]);
// eslint-disable-next-line no-useless-escape
export const URL_REGEX = /^(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
export const KRA_REGEX = /^\D\d{9}\D$/;
export const NOTES_LIMIT: number = 500;
export const CREDIT_NOTE_TYPES = ['return', 'write off', 'other'];
