import { createContext } from 'react';

interface AuthContextType {
  user: any;
  active: boolean;
  company_id: string;
  company_type: string;
  default_role: string;
  menu: string;
  roles: any[];
  setUser?: (user: any) => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  active: false,
  company_id: '',
  company_type: '',
  default_role: '',
  menu: 'top',
  roles: [],
  setUser: () => {}
});
