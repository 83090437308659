import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import RoleGuard from 'utils/route-guard/RoleGuard';

// render - pages
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const Preferences = Loadable(lazy(() => import('sections/apps/profiles/user/Preferences')));
const Requirements = Loadable(lazy(() => import('sections/apps/profiles/user/Requirements')));
const RoleCard = Loadable(lazy(() => import('sections/apps/profiles/user/RoleCard')));
const UserProfile = Loadable(lazy(() => import('pages/admin/users/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabSecurity = Loadable(lazy(() => import('sections/apps/profiles/user/TabSecurity')));

const SetupRoutes = {
  path: '/',
  children: [
    {
      path: '/profile',
      element: (
        <RoleGuard>
          <MainLayout />
        </RoleGuard>
      ),
      children: [
        {
          path: '/profile',
          element: <UserProfile />,
          children: [
            {
              path: '/profile/',
              element: <UserTabPersonal />
            },
            {
              path: '/profile/personal',
              element: <UserTabPersonal />
            },
            {
              path: '/profile/preferences',
              element: <Preferences />
            },
            {
              path: '/profile/security',
              element: <UserTabSecurity />
            },
            {
              path: '/profile/requirements',
              element: <Requirements />
            },
            {
              path: '/profile/client',
              element: <RoleCard />
            }
          ]
        },
        {
          path: '/profile/*',
          element: <Error404 />
        }
      ]
    }
  ]
};

export default SetupRoutes;
