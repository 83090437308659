import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AdminGuard from 'utils/route-guard/AdminGuard';

// render - pages
const AccountList = Loadable(lazy(() => import('pages/settings/account/chart-of-accounts')));
const Blank = Loadable(lazy(() => import('pages/blank/blank')));
const Clients = Loadable(lazy(() => import('pages/admin/clients/client-list')));
const DeploymentTasks = Loadable(lazy(() => import('pages/admin/settings/tasks')));
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const LedgerConfig = Loadable(lazy(() => import('pages/admin/settings/ledger')));
const Requirements = Loadable(lazy(() => import('pages/admin/settings/base')));
const Tabs = Loadable(lazy(() => import('pages/admin/settings/container')));
const UserListPage = Loadable(lazy(() => import('pages/admin/users/admin-user-list')));
// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  children: [
    {
      path: '/manage',
      element: (
        <AdminGuard>
          <MainLayout />
        </AdminGuard>
      ),
      children: [
        {
          path: '/manage',
          element: <Blank />
        },
        {
          path: '/manage/dashboard',
          element: <Blank />
        },
        {
          path: '/manage/clients',
          element: <Clients />
        },
        {
          path: '/manage/users',
          element: <UserListPage />
        },
        {
          path: '/manage/settings',
          element: <Tabs />,
          children: [
            {
              path: '/manage/settings',
              element: <LedgerConfig />
            },
            {
              path: '/manage/settings/gl',
              element: <LedgerConfig />
            },
            {
              path: '/manage/settings/requirements',
              element: <Requirements />
            },
            {
              path: '/manage/settings/tasks',
              element: <DeploymentTasks />
            },
            {
              path: '/manage/settings/accounts',
              element: <AccountList />
            }
          ]
        },
        {
          path: '/manage/*',
          element: <Error404 />
        }
      ]
    }
  ]
};

export default AdminRoutes;
