import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const purchases: NavItemType = {
  id: 'purchases',
  type: 'group',
  title: <FormattedMessage id="purchases" />,
  children: [
    {
      id: 'purchase-orders',
      title: <FormattedMessage id="purchase-orders" />,
      type: 'item',
      url: '/app/purchases/lpos'
    },
    {
      id: 'grns',
      title: <FormattedMessage id="grns" />,
      type: 'item',
      url: '/app/purchases/grns'
    },
    {
      id: 'supplier-invoices',
      title: <FormattedMessage id="supplier-invoices" />,
      type: 'item',
      url: '/app/purchases/invoices'
    },
    {
      id: 'supplier-credit-notes',
      title: <FormattedMessage id="supplier-credit-notes" />,
      type: 'item',
      url: '/app/purchases/credit-notes'
    }
  ]
};

export default purchases;
