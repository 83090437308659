import { AppstoreOutlined, CustomerServiceOutlined, FileOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  AppstoreOutlined,
  CustomerServiceOutlined,
  FileOutlined,
  SettingOutlined,
  UnorderedListOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const settings: NavItemType = {
  id: 'settings',
  type: 'group',
  title: <FormattedMessage id="settings" />,
  children: [
    {
      id: 'account',
      title: <FormattedMessage id="account" />,
      type: 'item',
      url: '/app/account',
      icon: icons.SettingOutlined
    },
    {
      id: 'inventory',
      title: (
        <>
          <FormattedMessage id="products" /> & <FormattedMessage id="services" />
        </>
      ),
      type: 'item',
      url: '/app/inventory',
      icon: icons.AppstoreOutlined
    }
  ]
};

export default settings;
