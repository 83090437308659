import { DefaultConfigProps } from 'types/config';
import { getThemeMode } from 'utils/helpers';

export const drawerWidth = 260;
export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

const config: DefaultConfigProps = {
  defaultPath: '/',
  appPath: '/app',
  adminPath: '/manage',
  loginPath: '/auth',
  setupPath: '/profile/client',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: false,
  isOnline: true,
  mode: getThemeMode(),
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
