import { combineReducers } from 'redux';
import menu from './menu';
import modals from './modals';
import snackbar from './snackbar';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  modals
});

export default reducers;
