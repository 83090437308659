import { Box, Typography } from '@mui/material';
import { AuthContext } from 'contexts/auth';
import { getMenuItems } from 'layout/MainLayout/menu-items';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'store';
import { NavItemType } from 'types/menu';
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user, active, default_role, company_type, company_id } = useContext(AuthContext);

  const menu = useSelector((state: any) => state.menu);
  const { drawerOpen } = menu;
  const [navigation, setNavigation] = useState<{ items: NavItemType[] }>({ items: [] });

  useEffect(() => {
    const menuItems = getMenuItems(user, active, default_role, company_type, company_id);
    if (menuItems) {
      setNavigation(menuItems);
    }
  }, [user, active, default_role, company_id]);

  const navGroups = navigation.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={[
        {
          '& > ul:first-of-type': { mt: 0 }
        },
        drawerOpen
          ? {
              pt: 2
            }
          : {
              pt: 0
            }
      ]}
    >
      {navGroups}
    </Box>
  );
};
export default Navigation;
