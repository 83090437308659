import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const payments: NavItemType = {
  id: 'payments',
  type: 'group',
  title: <FormattedMessage id="payments" />,
  children: [
    {
      id: 'expenses',
      title: <FormattedMessage id="expenses" />,
      type: 'item',
      url: '/app/payments/expenses/list'
    },
    {
      id: 'supplier-payments',
      title: <FormattedMessage id="supplier-payments" />,
      type: 'item',
      url: '/app/payments/purchases'
    },
    {
      id: 'customer-payments',
      title: <FormattedMessage id="customer-payments" />,
      type: 'item',
      url: '/app/payments/sales'
    },
    {
      id: 'refunds',
      title: <FormattedMessage id="refunds" />,
      type: 'item',
      url: '/app/payments/refunds'
    }
  ]
};

export default payments;
