import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  UserOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const setup: NavItemType = {
  id: 'main',
  type: 'group',
  title: <FormattedMessage id="dashboard" />,
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/profile',
      icon: icons.UserOutlined
    }
  ]
};

export default setup;
