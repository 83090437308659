import { NavItemType } from 'types/menu';
import setup from './setup';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [setup]
};

export default menuItems;
