import { WindowsOutlined } from '@ant-design/icons';
import {
  Box,
  ClickAwayListener,
  Grid2 as Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dot from 'components/@extended/Dot';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { drawerWidth } from 'config';
import { AuthContext } from 'contexts/auth';
import { getMenuItems } from 'layout/MainLayout/menu-items';
import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// ==============================|| HEADER CONTENT - MEGA MENU SECTION ||============================== //

const MegaMenuSection = () => {
  const theme = useTheme();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  const { user, active, default_role, company_id, company_type } = useContext(AuthContext);
  const menuItems = getMenuItems(user, active, default_role, company_type, company_id);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={[
          {
            color: 'text.primary'
          },
          open
            ? {
                bgcolor: iconBackColorOpen
              }
            : {
                bgcolor: iconBackColor
              }
        ]}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <WindowsOutlined />
      </IconButton>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-180, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                minWidth: 750,
                width: {
                  md: `calc(100vw - 100px)`,
                  lg: `calc(100vw - ${drawerWidth + 100}px)`,
                  xl: `calc(100vw - ${drawerWidth + 140}px)`
                },
                maxWidth: 1024
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <Grid container>
                    <Grid>
                      <Box
                        sx={{
                          p: 4,
                          '& .MuiList-root': {
                            pb: 0
                          },
                          '& .MuiListSubheader-root': {
                            p: 0,
                            pb: 1.5
                          },
                          '& .MuiListItemButton-root': {
                            p: 0.5,
                            '&:hover': {
                              background: 'transparent',
                              '& .MuiTypography-root': {
                                color: 'primary.main'
                              }
                            }
                          }
                        }}
                      >
                        <Grid container spacing={6}>
                          {menuItems?.items.map((item, idx) => (
                            <Grid key={idx}>
                              <List
                                component="nav"
                                aria-labelledby="nested-list-user"
                                subheader={
                                  <ListSubheader id="nested-list-user">
                                    <Typography variant="subtitle1" color="textPrimary">
                                      {item.title}
                                    </Typography>
                                  </ListSubheader>
                                }
                              >
                                {item.children
                                  ?.filter((child) => !!child.url)
                                  .map((subItem, index) => {
                                    return (
                                      <ListItemButton
                                        key={index}
                                        disableRipple
                                        component={Link}
                                        to={subItem.url || ''}
                                        onClick={() => setOpen(false)}
                                      >
                                        <ListItemIcon>
                                          <Dot size={7} color="secondary" variant="outlined" />
                                        </ListItemIcon>
                                        <ListItemText primary={subItem.title} />
                                      </ListItemButton>
                                    );
                                  })}
                              </List>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default MegaMenuSection;
