import { lazy } from 'react';

import { lazyRetry } from 'components/LazyRetry';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page
const Account = Loadable(lazy(() => lazyRetry(() => import('pages/reports/banking/account'))));
const AccountList = Loadable(lazy(() => lazyRetry(() => import('pages/settings/account/chart-of-accounts'))));
const AccountsList = Loadable(lazy(() => lazyRetry(() => import('pages/reports/banking/base'))));
const ActivityLog = Loadable(lazy(() => lazyRetry(() => import('pages/settings/account/audit-logs'))));
const BalanceSheet = Loadable(lazy(() => lazyRetry(() => import('pages/reports/bs'))));
const Blank = Loadable(lazy(() => lazyRetry(() => import('pages/blank/blank'))));
const CashAccountList = Loadable(lazy(() => lazyRetry(() => import('pages/settings/account/cash-accounts'))));
const CashSale = Loadable(lazy(() => lazyRetry(() => import('pages/sales/cash-sales/sale'))));
const CashSales = Loadable(lazy(() => lazyRetry(() => import('pages/sales/cash-sales/list'))));
const Categories = Loadable(lazy(() => lazyRetry(() => import('sections/apps/profiles/company/Categories'))));
const CsvPurchases = Loadable(lazy(() => lazyRetry(() => import('pages/reports/csv-purchases'))));
const CsvSales = Loadable(lazy(() => lazyRetry(() => import('pages/reports/csv-sales'))));
const CustomerBalances = Loadable(lazy(() => lazyRetry(() => import('pages/reports/customer-balances'))));
const CustomerList = Loadable(lazy(() => lazyRetry(() => import('pages/company/customer-list'))));
const CustomFields = Loadable(lazy(() => lazyRetry(() => import('pages/settings/account/fields'))));
const Dashboard = Loadable(lazy(() => lazyRetry(() => import('pages/dashboard/dashboard'))));
const DummyData = Loadable(lazy(() => lazyRetry(() => import('sections/apps/profiles/company/DummyData'))));
const Error404 = Loadable(lazy(() => lazyRetry(() => import('pages/maintenance/404'))));
const ExpensePage = Loadable(lazy(() => lazyRetry(() => import('pages/expenses/expense'))));
const Expenses = Loadable(lazy(() => lazyRetry(() => import('pages/expenses/list'))));
const FeeNote = Loadable(lazy(() => lazyRetry(() => import('pages/sales/fee-notes/note'))));
const FeeNotes = Loadable(lazy(() => lazyRetry(() => import('pages/sales/fee-notes/list'))));
const FieldTabs = Loadable(lazy(() => lazyRetry(() => import('pages/settings/account/container'))));
const FilesTabs = Loadable(lazy(() => lazyRetry(() => import('pages/settings/files/files'))));
const GeneralLedger = Loadable(lazy(() => lazyRetry(() => import('pages/accounting/gl'))));
const GeneralLedgerReport = Loadable(lazy(() => lazyRetry(() => import('pages/reports/gl'))));
const GRNPage = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/grns/grn'))));
const GrnsList = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/grns/list'))));
const ImportExport = Loadable(lazy(() => lazyRetry(() => import('sections/apps/profiles/company/ImportExport'))));
const InvoicePage = Loadable(lazy(() => lazyRetry(() => import('pages/sales/invoices/invoice'))));
const Invoices = Loadable(lazy(() => lazyRetry(() => import('pages/sales/invoices/list'))));
const JournalEntries = Loadable(lazy(() => lazyRetry(() => import('pages/journal-entries/list'))));
const JournalEntry = Loadable(lazy(() => lazyRetry(() => import('pages/journal-entries/entry'))));
const JournalEntryReport = Loadable(lazy(() => lazyRetry(() => import('pages/reports/journal'))));
const LPOPage = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/lpos/lpo'))));
const Preferences = Loadable(lazy(() => lazyRetry(() => import('sections/apps/profiles/company/Preferences'))));
const ProductList = Loadable(lazy(() => lazyRetry(() => import('pages/company/product-list'))));
const ProductTabs = Loadable(lazy(() => lazyRetry(() => import('pages/settings/products/products'))));
const ProfitAndLoss = Loadable(lazy(() => lazyRetry(() => import('pages/reports/pnl'))));
const PurchaseOrderList = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/lpos/list'))));
const PurchasesAgeAnalysis = Loadable(lazy(() => lazyRetry(() => import('pages/reports/purchases-age-analysis'))));
const PurchasesReport = Loadable(lazy(() => lazyRetry(() => import('pages/reports/purchases'))));
const Quotation = Loadable(lazy(() => lazyRetry(() => import('pages/sales/quotations/quotation'))));
const Quotations = Loadable(lazy(() => lazyRetry(() => import('pages/sales/quotations/list'))));
const Refund = Loadable(lazy(() => lazyRetry(() => import('pages/refunds/refund'))));
const RefundList = Loadable(lazy(() => lazyRetry(() => import('pages/refunds/list'))));
const Reports = Loadable(lazy(() => lazyRetry(() => import('pages/reports/links'))));
const Requirements = Loadable(lazy(() => lazyRetry(() => import('sections/apps/profiles/company/Requirements'))));
const SalesAgeAnalysis = Loadable(lazy(() => lazyRetry(() => import('pages/reports/sales-age-analysis'))));
const SalesCreditNotePage = Loadable(lazy(() => lazyRetry(() => import('pages/sales/credit-notes/credit-note'))));
const SalesCreditNotesList = Loadable(lazy(() => lazyRetry(() => import('pages/sales/credit-notes/list'))));
const SalesDeliveries = Loadable(lazy(() => lazyRetry(() => import('pages/sales/deliveries/list'))));
const SalesDelivery = Loadable(lazy(() => lazyRetry(() => import('pages/sales/deliveries/delivery'))));
const SalesOrder = Loadable(lazy(() => lazyRetry(() => import('pages/sales/orders/order'))));
const SalesOrders = Loadable(lazy(() => lazyRetry(() => import('pages/sales/orders/list'))));
const SalesPaymentPage = Loadable(lazy(() => lazyRetry(() => import('pages/sales/payments/payment'))));
const SalesPaymentsList = Loadable(lazy(() => lazyRetry(() => import('pages/sales/payments/list'))));
const SalesReport = Loadable(lazy(() => lazyRetry(() => import('pages/reports/sales'))));
const ServiceList = Loadable(lazy(() => lazyRetry(() => import('pages/company/service-list'))));
const StaffList = Loadable(lazy(() => lazyRetry(() => import('pages/company/staff-list'))));
const SupplierBalances = Loadable(lazy(() => lazyRetry(() => import('pages/reports/supplier-balances'))));
const SupplierCreditNotePage = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/credit-notes/credit-note'))));
const SupplierCreditNotesList = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/credit-notes/list'))));
const SupplierInvoicePage = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/supplier-invoices/invoice'))));
const SupplierInvoicesList = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/supplier-invoices/list'))));
const SupplierList = Loadable(lazy(() => lazyRetry(() => import('pages/company/supplier-list'))));
const SupplierPaymentPage = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/payments/payment'))));
const SupplierPaymentsList = Loadable(lazy(() => lazyRetry(() => import('pages/purchases/payments/list'))));
const Taxes = Loadable(lazy(() => lazyRetry(() => import('pages/company/taxes'))));
const Terms = Loadable(lazy(() => lazyRetry(() => import('pages/company/payment-terms'))));
const TransactionTabs = Loadable(lazy(() => lazyRetry(() => import('pages/reports/banking/accounts'))));
const TrialBalance = Loadable(lazy(() => lazyRetry(() => import('pages/reports/tb'))));
const VatReport = Loadable(lazy(() => lazyRetry(() => import('pages/reports/vat-report'))));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/app',
      element: <Dashboard />
    },
    {
      path: '/app/staff',
      element: <StaffList />
    },
    {
      path: '/app/customers',
      element: <CustomerList />
    },
    {
      path: '/app/suppliers',
      element: <SupplierList />
    },
    {
      path: '/app/accounting',
      children: [
        { path: '/app/accounting', element: <JournalEntries /> },
        { path: '/app/accounting/journal-entries', element: <JournalEntries /> },
        { path: '/app/accounting/journal-entries/:uid', element: <JournalEntry /> },
        { path: '/app/accounting/journal-entries/create', element: <JournalEntry /> },
        { path: '/app/accounting/gl', element: <GeneralLedger /> }
      ]
    },
    {
      path: '/app/reports',
      children: [
        {
          path: '/app/reports',
          element: <Reports />
        },
        {
          path: '/app/reports/gl',
          element: <GeneralLedgerReport />
        },
        {
          path: '/app/reports/journal',
          element: <JournalEntryReport />
        },
        {
          path: '/app/reports/pnl',
          element: <ProfitAndLoss />
        },
        {
          path: '/app/reports/bs',
          element: <BalanceSheet />
        },
        {
          path: '/app/reports/tb',
          element: <TrialBalance />
        },
        {
          path: '/app/reports/sales-age-analysis',
          element: <SalesAgeAnalysis />
        },
        {
          path: '/app/reports/customer-balances',
          element: <CustomerBalances />
        },
        {
          path: '/app/reports/csv-sales',
          element: <CsvSales />
        },
        {
          path: '/app/reports/csv-purchases',
          element: <CsvPurchases />
        },
        {
          path: '/app/reports/vat-report',
          element: <VatReport />
        },
        {
          path: '/app/reports/banking',
          element: <TransactionTabs />,
          children: [
            {
              path: '/app/reports/banking',
              element: <AccountsList />
            },
            {
              path: '/app/reports/banking/:uid',
              element: <Account />
            }
          ]
        },
        {
          path: '/app/reports/supplier-balances',
          element: <SupplierBalances />
        },
        {
          path: '/app/reports/purchases-age-analysis',
          element: <PurchasesAgeAnalysis />
        },
        {
          path: '/app/reports/sales',
          element: <SalesReport />
        },
        {
          path: '/app/reports/purchases',
          element: <PurchasesReport />
        }
      ]
    },
    {
      path: '/app/payments',
      children: [
        {
          path: '/app/payments',
          element: <Expenses />
        },
        {
          path: '/app/payments/expenses/list',
          element: <Expenses />
        },
        {
          path: '/app/payments/expenses/:uid',
          element: <ExpensePage />
        },
        {
          path: '/app/payments/expenses/create',
          element: <ExpensePage />
        },
        {
          path: '/app/payments/sales',
          element: <SalesPaymentsList />
        },
        {
          path: '/app/payments/sales/create',
          element: <SalesPaymentPage />
        },
        {
          path: '/app/payments/sales/:uid',
          element: <SalesPaymentPage />
        },
        {
          path: '/app/payments/purchases',
          element: <SupplierPaymentsList />
        },
        {
          path: '/app/payments/purchases/create',
          element: <SupplierPaymentPage />
        },
        {
          path: '/app/payments/purchases/:uid',
          element: <SupplierPaymentPage />
        },
        {
          path: '/app/payments/refunds',
          element: <RefundList />
        },
        {
          path: '/app/payments/refunds/create',
          element: <Refund />
        },
        {
          path: '/app/payments/refunds/:uid',
          element: <Refund />
        }
      ]
    },
    {
      path: '/app/purchases',
      children: [
        {
          path: '/app/purchases/lpos',
          element: <PurchaseOrderList />
        },
        {
          path: '/app/purchases/lpos/create',
          element: <LPOPage />
        },
        {
          path: '/app/purchases/lpos/:uid',
          element: <LPOPage />
        },
        {
          path: '/app/purchases/credit-notes',
          element: <SupplierCreditNotesList />
        },
        {
          path: '/app/purchases/credit-notes/create',
          element: <SupplierCreditNotePage />
        },
        {
          path: '/app/purchases/credit-notes/:uid',
          element: <SupplierCreditNotePage />
        },
        {
          path: '/app/purchases/invoices',
          element: <SupplierInvoicesList />
        },
        {
          path: '/app/purchases/invoices/create',
          element: <SupplierInvoicePage />
        },
        {
          path: '/app/purchases/invoices/:uid',
          element: <SupplierInvoicePage />
        },
        {
          path: '/app/purchases/grns',
          element: <GrnsList />
        },
        {
          path: '/app/purchases/grns/create',
          element: <GRNPage />
        },
        {
          path: '/app/purchases/grns/:uid',
          element: <GRNPage />
        }
      ]
    },
    {
      path: '/app/sales',
      children: [
        {
          path: '/app/sales/cash-sales',
          element: <CashSales />
        },
        {
          path: '/app/sales/cash-sales/:uid',
          element: <CashSale />
        },
        {
          path: '/app/sales/cash-sales/create',
          element: <CashSale />
        },
        {
          path: '/app/sales/fee-notes',
          element: <FeeNotes />
        },
        {
          path: '/app/sales/fee-notes/:uid',
          element: <FeeNote />
        },
        {
          path: '/app/sales/fee-notes/create',
          element: <FeeNote />
        },
        {
          path: '/app/sales/quotations',
          element: <Quotations />
        },
        {
          path: '/app/sales/quotations/create',
          element: <Quotation />
        },
        {
          path: '/app/sales/quotations/:uid',
          element: <Quotation />
        },
        {
          path: '/app/sales/orders',
          element: <SalesOrders />
        },
        {
          path: '/app/sales/orders/create',
          element: <SalesOrder />
        },
        {
          path: '/app/sales/orders/:uid',
          element: <SalesOrder />
        },
        {
          path: '/app/sales/deliveries',
          element: <SalesDeliveries />
        },
        {
          path: '/app/sales/deliveries/create',
          element: <SalesDelivery />
        },
        {
          path: '/app/sales/deliveries/:uid',
          element: <SalesDelivery />
        },
        {
          path: '/app/sales/invoices',
          element: <Invoices />
        },
        {
          path: '/app/sales/invoices/create',
          element: <InvoicePage />
        },
        {
          path: '/app/sales/invoices/:uid',
          element: <InvoicePage />
        },
        {
          path: '/app/sales/credit-notes',
          element: <SalesCreditNotesList />
        },
        {
          path: '/app/sales/credit-notes/create',
          element: <SalesCreditNotePage />
        },
        {
          path: '/app/sales/credit-notes/:uid',
          element: <SalesCreditNotePage />
        }
      ]
    },
    {
      path: '/app/account',
      element: <FieldTabs />,
      children: [
        {
          path: '/app/account',
          element: <Preferences />
        },
        {
          path: '/app/account/preferences',
          element: <Preferences />
        },
        {
          path: '/app/account/form-fields',
          element: <CustomFields />
        },
        {
          path: '/app/account/requirements',
          element: <Requirements />
        },
        {
          path: '/app/account/region',
          element: <Blank />
        },
        {
          path: '/app/account/terms',
          element: <Terms />
        },
        {
          path: '/app/account/imports',
          element: <ImportExport />
        },
        {
          path: '/app/account/dummy-data',
          element: <DummyData />
        },
        {
          path: '/app/account/activity-log',
          element: <ActivityLog />
        },
        {
          path: '/app/account/cash-accounts',
          element: <CashAccountList />
        },
        {
          path: '/app/account/chart-of-accounts',
          element: <AccountList />
        }
      ]
    },
    {
      path: '/app/files',
      element: <FilesTabs />,
      children: [
        {
          path: '/app/files',
          element: <Blank />
        },
        {
          path: '/app/files/staff',
          element: <Blank />
        },
        {
          path: '/app/files/customers',
          element: <Blank />
        },
        {
          path: '/app/files/suppliers',
          element: <Blank />
        }
      ]
    },
    {
      path: '/app/inventory',
      element: <ProductTabs />,
      children: [
        {
          path: '/app/inventory',
          element: <ProductList />
        },
        {
          path: '/app/inventory/products',
          element: <ProductList />
        },
        {
          path: '/app/inventory/services',
          element: <ServiceList />
        },
        {
          path: '/app/inventory/categories',
          element: <Categories />
        },
        {
          path: '/app/inventory/taxes',
          element: <Taxes />
        }
      ]
    },
    {
      path: '/app/*',
      element: <Error404 />
    }
  ]
};

export default MainRoutes;
