import config from 'config';
import { AuthContext } from 'contexts/auth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/user';

// ==============================|| ROLE GUARD ||============================== //

const RoleGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const { active } = useContext(AuthContext);

  useEffect(() => {
    if (!active) {
      navigate(config.loginPath, { replace: true });
    }
  }, [active, navigate]);

  return children;
};

export default RoleGuard;
