import { lazy } from 'react';

import Loadable from 'components/Loadable';
import CommonLayout from 'layout/CommonLayout';
import LandingGuard from 'utils/route-guard/LandingGuard';

// render - sample page
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const Landing = Loadable(lazy(() => import('pages/landing/landing')));

// ==============================|| MAIN ROUTING ||============================== //

const LoaderRoutes = {
  path: '/',
  element: (
    <LandingGuard>
      <CommonLayout />
    </LandingGuard>
  ),
  children: [
    {
      path: '/',
      element: <Landing />
    },
    {
      path: '*',
      element: <Error404 />
    }
  ]
};

export default LoaderRoutes;
