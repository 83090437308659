import config from 'config';
import { AuthContext } from 'contexts/auth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/user';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { active } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (active) {
      navigate(config.appPath, { replace: true });
    }
  }, [active, navigate]);

  return children;
};

export default GuestGuard;
